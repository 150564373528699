import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserPreferencesService } from '@fi/app/client';
import { APPLICATION_ID, ISite, IUser, LabelRoles } from '@fi/app/core';
import { UsersService } from '@fi/app/core/services/users.service';
import { CsvUtilsService } from '@fi/app/core/services/csv-utils.service';
import dayjs from 'dayjs';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UTENTI_CONFIG_PROVIDER, UtentiModuleOptions } from '../module.options';

@Component({
  selector: 'facile-utenti',
  template: `
    <div class="h-full flex flex-col">
      <fullstack-header></fullstack-header>

      <ng-template header-body>
        <h1 class="tx-title-1">Utenti</h1>
        <h2 class="tx-subtitle mb-0">Lista</h2>
      </ng-template>

      <ng-template header-body>
        <div class="text-end align-items-center">
          <button mat-icon-button (click)="download()" pTooltip="Esporta CSV" color="secondary">
            <mat-icon>file_download</mat-icon>
          </button>
        </div>
      </ng-template>

      <fullstack-datatable
        class="flex-1"
        [list]="users$ | async"
        [cmItems]="cmItems"
        (selected)="selected = $event"
        [clientExport]="false"
        [showColumnSelector]="true"
        [visibleColumns]="visibleColumns"
        (selectedColumnsChoosed)="saveSelectedColumn($event)"
        (rowsChange)="saveRowCount($event)"
        [maxRows]="maxRows"
      >
        <div t-header>
          <div t-column [name]="'Nome'" field="firstName" clientFilter="firstName">
            <td *t-cell="let row">{{ row?.firstName }}</td>
          </div>
          <div t-column [name]="'Cognome'" field="lastName" clientFilter="lastName">
            <td *t-cell="let row">{{ row?.lastName }}</td>
          </div>
          <div t-column [name]="'Email'" field="email" clientFilter="email">
            <td *t-cell="let row">{{ row?.email }}</td>
          </div>
          <div t-column [name]="'Telefono'" field="mobilePhone" clientFilter="mobilePhone">
            <td *t-cell="let row">{{ row?.mobilePhone }}</td>
          </div>
          <div t-column [name]="'Ruolo'" field="roles" clientFilter="roles">
            <td *t-cell="let row">{{ row?.roles }}</td>
          </div>
          <div t-column [name]="'Attivo'" field="active">
            <td *t-cell="let row">{{ row?.active ? 'Attivo' : 'Non attivo' }}</td>
          </div>
          <div t-column [name]="'Ultimo Login'" field="lastLoginInstant">
            <td *t-cell="let row">{{ row?.lastLoginInstant | date: 'short' }}</td>
          </div>
          <div t-column [name]="'Nome Sede'" field="site.name" clientFilter="site.name">
            <td *t-cell="let row">{{ row?.site?.name }}</td>
          </div>
          <div t-column [name]="'Provincia Sede'" field="site.address.province" clientFilter="site.address.province">
            <td *t-cell="let row">{{ row?.site?.address?.province }}</td>
          </div>
          <div t-column [name]="'Area Sede'" field="site.area" clientFilter="site.area">
            <td *t-cell="let row">{{ row?.site?.area }}</td>
          </div>
          <div t-column [name]="'BC Id'" field="bcId" clientFilter="bcId">
            <td *t-cell="let row">{{ row?.bcId }}</td>
          </div>
          <div t-column [name]="'Codice fiscale'" field="fiscalCode" clientFilter="fiscalCode">
            <td *t-cell="let row">{{ row?.fiscalCode }}</td>
          </div>
          <div t-column [name]="'Abilitato'" field="isQualified" clientFilter="isQualified">
            <td *t-cell="let row">{{ row?.isQualified ? 'Si' : 'No' }}</td>
          </div>
          <div t-column [name]="'Fittizio'" field="isDummy" clientFilter="isDummy">
            <td *t-cell="let row">{{ row?.isDummy ? 'Si' : 'No' }}</td>
          </div>
          <div [name]="'Delta appuntamenti'" t-column field="eventsCountDelta" clientFilter="eventsCountDelta">
            <td *t-cell="let row">{{ row?.eventsCountDelta }}</td>
          </div>
          <div [name]="'Limite mensile'" t-column field="monthLimit" clientFilter="monthLimit">
            <td *t-cell="let row">{{ row?.monthLimit || '-' }}</td>
          </div>
        </div>
      </fullstack-datatable>
    </div>
  `,
})
export class UtentiComponent implements OnInit {
  cmItems: MenuItem[] = [
    {
      label: 'Dettaglio',
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        this.gotoDetail();
      },
    },
  ];

  users$: Observable<ViewUser[]>;
  selected: ViewUser = null;
  visibleColumns: Array<string> = [];
  tableRef = 'UtentiComponent';
  maxRows: number = 25;
  loading: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    @Inject(UTENTI_CONFIG_PROVIDER)
    public utentiModuleOption: UtentiModuleOptions,
    private userPreferencesService: UserPreferencesService,
    private csvUtils: CsvUtilsService,
  ) {}

  ngOnInit(): void {
    this.users$ = this.usersService.excludeNetworkUsers$(['can_read_users_list']).pipe(
      map((us) => {
        return us?.map(
          (
            u: IUser & {
              site: ISite;
              bcId: string;
              fiscalCode: string;
              isQualified: boolean;
              isDummy: boolean;
              eventsCountDelta: number;
              monthLimit: number;
            },
          ) =>
            ({
              id: u.id,
              firstName: u.firstName,
              lastName: u.lastName,
              email: u.email,
              mobilePhone: u.mobilePhone,
              roles: u.registrations
                ? this.buildRoles(u.registrations.find((reg) => reg.applicationId === APPLICATION_ID.FI)?.roles)
                : [],
              active: u.active,
              lastLoginInstant: dayjs(u.lastLoginInstant).toDate(),
              site: u.site,
              bcId: u.bcId,
              fiscalCode: u.fiscalCode,
              isQualified: u.isQualified,
              isDummy: u.isDummy,
              eventsCountDelta: u.eventsCountDelta,
              monthLimit: u.monthLimit,
            }) as ViewUser,
        );
      }),
    );
    this.loading = true;
    this.userPreferencesService.findByTableRef(this.tableRef).subscribe((preferences) => {
      if (preferences?.fields?.length > 0) {
        this.visibleColumns = preferences.fields;
      }
      this.maxRows = preferences?.rowCount ?? this.maxRows;
      this.loading = false;
    });
  }

  gotoDetail() {
    this.router.navigate([`../upsert/main-info`], {
      queryParams: { id: this.selected.id },
      relativeTo: this.activatedRoute,
    });
  }
  private buildRoles(roles: string[]) {
    return roles
      ? roles
          .map((r) => (LabelRoles[r] ? LabelRoles[r] : null))
          .filter((role) => !!role)
          .join(', ')
      : '';
  }

  saveSelectedColumn(selectedColumns: Array<string>) {
    this.userPreferencesService
      .create({
        tableRef: this.tableRef,
        fields: selectedColumns,
      })
      .subscribe(() => {});
  }
  saveRowCount(rows: number) {
    this.userPreferencesService
      .create({
        tableRef: this.tableRef,
        rowCount: rows,
      })
      .subscribe(() => {});
  }
  download() {
    this.csvUtils.arrayToCsv(this.users$, 'users_list').subscribe();
  }
}
type ViewUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  roles: string;
  lastLoginInstant: Date;
  site: ISite;
  bcId: string;
  fiscalCode: string;
  isQualified: boolean;
  isDummy: boolean;
};
