import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'utenti-upsert-component',
  template: `

    <div class="flex w-full bg-white drop-shadow-1">
      <div class="p-4">    <h1 class="text-3xl">Utente</h1>
      </div>

    </div>

      <fullstack-tabs>
        <tab [label]="'Dettaglio'" [url]="'./main-info'"></tab>
        <ng-container *permissions="['user_manager', 'can_view_users_details']">
          <tab
            *ngIf="id"
            [label]="'Dati aggiuntivi'"
            [url]="'./additional-info'"
          ></tab>
        </ng-container>
      </fullstack-tabs>
  <div class="p-4">
    <router-outlet></router-outlet>
  </div>`,
})
export class UtentiUpsertTabsComponent implements OnInit {
  public id: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params.id;
    });
  }
}
