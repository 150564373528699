import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QrShowComponent } from '@fi/app/components/qr-show/qr-show.component';
import { DirectiveModule } from '@fi/app/core/directives/directive.module';
import { InputBoxModule, UiModule } from '@fi/app/ui';
import { SiteModule } from '../site';
import { UtentiAdditionalDataFrom } from './components/forms/additional-data.form';
import { UtentiAdditionalInfo } from './components/pages/additional-info.component';
import { UtentiUpsertComponent } from './components/pages/upsert/utenti-upsert.component';
import { UtentiUpsertTabsComponent } from './components/pages/utenti-upsert-tabs.component';
import { UtentiComponent } from './components/utenti.component';
import { UTENTI_CONFIG_PROVIDER, UtentiModuleOptions } from './module.options';
import { UtentiRoutingModule } from './utenti-routing.module';

@NgModule({
  declarations: [
    UtentiComponent,
    UtentiUpsertComponent,
    UtentiUpsertTabsComponent,
    UtentiAdditionalInfo,
    UtentiAdditionalDataFrom,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DirectiveModule,
    UiModule,
    ReactiveFormsModule,
    UtentiRoutingModule,
    SiteModule,
    InputBoxModule,
    QrShowComponent,
    DirectiveModule,
  ],
  providers: [],
  exports: [UtentiAdditionalDataFrom, DirectiveModule],
})
export class UtentiModule {
  static forRoot(config?: UtentiModuleOptions): ModuleWithProviders<UtentiModule> {
    return {
      ngModule: UtentiModule,
      providers: [
        {
          provide: UTENTI_CONFIG_PROVIDER,
          useValue: config,
        },
      ],
    };
  }
}
